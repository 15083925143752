/* Basic table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

/* Table body styling */
tbody {
  width: 100%;
}

/* Table row styling */
tr {
  border: 1px solid #dddddd;
}

/* Table cell styling */
td {
  padding: 4px 6px;
  border: 1px solid #dddddd;
}

/* Table header styling */
th {
  background-color: #f2f2f2;
  color: #555;
  font-weight: bold;
  padding: 12px 15px;
  border: 2px solid #dddddd;
}

/* Alternating row colors */
/* tr:nth-of-type(even) {
  background-color: #f9f9f9;
} */

/* Hover effect for rows */
tr:hover {
  background-color: #f1f1f1;
}

.btn-list{
  outline:none;
  box-shadow: none;
  border-radius: 3px;
  border: none;
  padding:12px 12px;
  background-color: #009644;
  color: white !important;
  /* position: relative; */
  white-space: nowrap;
  width: 100%;
}
.disabled{
  opacity:50%;
}
.list-editor{
  width:4px;
  margin: 0 auto;
  height:21px;
  position:relative;
}
.editor-dropdown{
  background-color: #f1f1f1;
  border: 1px solid #dddddd;
  top: -9px;
  left: 15px;
  box-shadow: 0 4px 10px -2px #E0E3E5;
  position:absolute;
}
.list-editor-option{
  font-size:14px;
  padding: 2px;
}
.delete-list{
  border-top: 1px solid #dfdfdf;
}
.editor-dropdown-container{
  display:flex;
  flex-direction: column;
  width:100%;
  height:100%;
}
.td-update{
  width:100px;
}
.td-editor{
  width:25px;
}
.confirmation-dialog{
  position:absolute;
  background-color: white;
  padding:10px;
  width:220px;
  height:116px;
  top: calc(50% - 58px);  
  right: calc(50% - 110px);
  z-index:999999;
  border-radius: 6px;
  outline: 5000px solid rgba(0, 0, 0, .75)
}
.btn-confirm {
  margin-top:8px;
}
.edit-component{
  position:absolute;
  background-color: white;
  border-radius: 6px;
  width:280px;;
  height:200px;
  outline: 5000px solid rgba(0, 0, 0, .75);
  top: calc(50% - 100px);  
  right: calc(50% - 140px);
  z-index:999999;
  padding:20px;
}
.edit-jaarafsluiting{
  margin-bottom:15px;
}
.schedule-component{
  position: absolute;
  background-color:white;
  top: calc(50% - 175px);  
  right: calc(50% - 153px);
  /* height:200px;
  width:200px; */
  border-radius:6px;
  padding:5px 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}
.custom-date-time-picker{
  position:relative;
  margin-top:3px;
  height:240px;
}
.schedule-header{
  position:relative;
  height:18px;
  margin-bottom:8px;
}

.schedule-btn{
  outline:none;
  box-shadow: none;
  border-radius: 3px;
  border: none;
  padding:5px 10px;    
  background-color: #009644;
  color: white !important;
  display: inline-block;
  margin-right:4px;
  margin-top: 4px;
  height: 26px;
}
.schedule-close-window{
  color:#009644;
  position:absolute;
  right: -4px;
  top: -4px;
}

.custom-date-input{
  border-radius:2px;
  font-size: 14px;
}
.react-datepicker{
  /* width: 330px!important; */
}
.react-datepicker__time-container {
  width: 88px!important;
}
.interval-input{
  width:36px;
}

.react-datepicker__day--keyboard-selected{
  border-radius: 0!important;
  background-color: white!important;
  color: #000!important;
}
.highlighted-date{
  border-radius: 0.3rem!important;
  background-color: #bad9f1!important;
  color: rgb(0, 0, 0)!important;
}
.highlighted-time{
  border-radius: 0.3rem!important;
  background-color: #bad9f1!important;
  color: rgb(0, 0, 0)!important;
}
.disabled{
  opacity:.75;
  background-color:#ccc;
}
.jaarafsluiting-content{
width:200px;
}
.list-header{
  background-color:#f1f1f1;
}
.list-case,.list-administration, .list-year, .list-budgetscenario, .list-fullname, .list-stamp{
  position:relative;
}
.list-case:hover, .list-administration:hover, .list-year:hover, .list-budgetscenario:hover, .list-fullname:hover, .list-stamp:hover{
  text-decoration: underline;
}
.sort-icon{
  color:#333;
}
.list-sort{
  display:inline-flex;
  /* right:10px;
  top:10px;
  margin-right:5px;  */
}
.list-flex{
  display:inline-flex;
  width:100%;
  justify-content: space-between;
}
.no-wrap{
  white-space: nowrap;
}

.filter-icon{
  font-size:11px;
  color:#333;
  font-weight: 300;
  display:inline;
  position:relative;
  top:5px;
}
.list-sort .fa-sort-up{
  position:relative;
  display:inline;
  bottom:-5px;
}
.list-sort .fa-sort-down{
  position:relative;
  display:inline;
  bottom:3px;
}
.year-el, .case-el, .administration-el, .budgetscenario-el, .name-el, .stamp-el, .created-el, .planned-el{
  margin-right:5px;
  display:inline;
}
.filter-container{
  position:absolute;
  top:8px;
  left:9px; 
  width:100%;
}

.filter-component{
  z-index:99;
  position:absolute;
  top:0px;
}
.filter-input{
  padding:2px;
  width: 92%;
  position: relative;
  top: -4px;
  right: 4px;

}
.filter-input:focus{
  content: none;
}
.filter-input:focus{
  outline:none;
}
.li-filter::marker {
  content: none;
}
.li-filter{
  padding:2px 4px;
}
.ul-filter{
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.budgetscenario-el .filter-close{
  right: 18px!important;
}
.filter-close{
  position:absolute;
  right: 13px;
  top: 2px;
  font-size: 13px;
}
.list-scheduler-container{
  position:relative;
}
/* For Chrome, Safari, Edge, and Opera */
/* input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}