.scheduler-component{
  position:absolute;
  z-index:98;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding:15px;
  display:flex;
  top: 0px;  
  left: 0px;
  flex-direction: column;
  border-radius: 8px;
  min-width: 300px;
}
.scheduler-close-window, .schedule-repeat-close-window {
  color: #009644;
  position: absolute;
  right: 6px;
  top: 2px;
}

.scheduler-table{
  margin-top: 5px;
  margin-bottom: 10px;
}

.scheduler-tr{
  background-color: #f1f1f1;
}
.scheduler-td{
  /* width:200px; */
  padding-left: 8px;
}

.edit-scheduler-container{
  position: relative;
  width:0px;
  height:0px;
}
.scheduler-edit{
  border-bottom:1px solid #dddddd;
}
.scheduler-edit-btn{
  padding-left:3px;
  padding-right:2px;
}

.edit-scheduler{
  position: absolute;
  z-index: 99;
  left:16px;
  top:-5px;
  background-color: #f1f1f1;
  border: 1px solid #dddddd;
}
.edit-scheduler-td{
  width: 10px;
}
.edit-scheduler p {
  font-size: 14px;
  padding: 2px;
}

.datepicker-el{
  width: 0px;
  height: 0px;
  border:none;
  opacity: 0;
  /* display: none; */
}
.scheduled-wrapper-header{
  display:inline;
}

.scheduled-wrapper-date p{
  font-size: 15px;
}
.scheduled-status{ 
  margin-left:4px;
  font-size:16px;
}
.scheduled-wrapper .fa-xmark{
  color:red;
  top:1px;
  position:relative;  
}
.scheduled-wrapper .fa-check{
 color: #009644;
}
.scheduled-wrapper .fa-repeat, .scheduled-wrapper .fa-clock{
  color:rgb(0, 110, 255);
 }
.schedule-selector{
  width:100%;
}
.schedule-selector-label{
  width:100%;
  margin-bottom:4px;
}
.schedule-selector-container{
  display: flex;
}
.schedule-selector-btn{
  display: inline-block;
}

.schedule-repeat-component{
  position:absolute;
  border-radius: 10px;
  z-index:98;
  bottom:0px;
  left:0px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  padding: 15px;
  width:300px;
}

.schedule-repeat-content{
  display:flex;
  flex-direction: column;
}

.schedule-slider-container {
  width: 100%;
  min-width: 211px;
  max-width: 400px;
  display: flex; 
  margin-bottom:8px;
}
.schedule-time-slider{
  display:inline-block;
  position:relative;
  top: 10px;
}

.schedule-time-display {
  white-space: nowrap; 
  font-size: 18px;
  color: rgb(105, 105, 105);
  min-width: 77px;
  font-weight: 500;
  text-align: right;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.schedule-slider-container input[type="range"] {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 250px;
  height: 6px; /* Height of the track */
  background: #ddd; /* Track color */
  border-radius: 5px;
  outline: none;
  transition: background 0.3s;
}

/* Style the track (bar) */
.schedule-slider-container input[type="range"]::-webkit-slider-runnable-track {
  background: #009644; /* Green track */
  height: 6px; /* Track height */
  border-radius: 5px;
}

.schedule-slider-container input[type="range"]::-moz-range-track {
  background: #009644;
  height: 6px; /* Track height */
  border-radius: 5px;
}

/* Style the thumb (the circular handle) */
.schedule-slider-container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 16px; /* Width of the thumb */
  height: 16px; /* Height of the thumb */
  background: #ffffff; /* Thumb color */
  border-radius: 50%; /* Make it round */
  cursor: pointer;
  outline:#009644 2px solid;
  margin-top: -5px; /* Center it vertically over the track */
}

.schedule-slider-container input[type="range"]::-moz-range-thumb {
  width: 16px; /* Width of the thumb */
  height: 16px; /* Height of the thumb */
  background: #009644; /* Thumb color */
  border-radius: 50%; /* Make it round */
  cursor: pointer;
  transition: all ease 100ms;
}
.schedule-repeat-input-container{
  /* display:flex; */
  width: 100%;
  margin-bottom: 10px;
}
.schedule-repeat-every{
  white-space: nowrap; 
  margin-right: 4px;
}
.schedule-repeat-input-content{
  display:flex;
}
.schedule-repeat-input {
  padding: 4px; /* Padding for a cleaner look */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1rem; /* Font size */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s; /* Smooth transition */
  width:60px;
  margin-right:4px;
}

/* Change border color on focus */
.schedule-repeat-input:focus {
  border-color: #009644; /* Change border color when focused */
}


