.exactglobe-component{
  width:100%;
}

.exactglobe-authform{
  display: flex;
  flex-direction: column;
  padding: 10px 75px;
  margin:25px 75px;
  width:calc(100% - 150px);
  background-color: #F0F0F0;
  border-radius:6px;
}

.authform-header{
  width:100%;
  display: flex;
  align-items: center; /* Vertically center items */
} 

.authform-header p{
  font-size: 20px;
  margin: 0; /* Remove default margins from <p> */
  padding-right:6px;
}

.vertical-line {
  width: calc(100% - 6px); /* Line thickness */
  background-color: #d3d3d3; /* Line color */
  border-radius: 2px;
  height: 2px; /* Full height of the container */
}

.authform-body{
  padding:6px 6px;
  display:flex;
  flex-direction: column;
}

.authform-config{
  display:flex;
  flex-direction: row;
}

.authform-inputlabel{
  width:45%;
}

.authform-inputcontainer{
  width:70%;
}

.authform-inputcontainer input{
  width:100%;
  padding:4px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  transition: border-color 0.3s; /* Smooth transition for border color */
}

.authform-config{
  margin-bottom: 6px;
}
.authform-username p{
  padding-left:33px;
}
.authform-password p{
  padding-left:37px;
}

.authform-footer{
  margin-top:8px;
}

.dropdown {
  width:100%;
  position: relative; /* Position relative to contain the dropdown content */
  display: inline-block; /* Allow the dropdown to fit its content */
}

.dropdown-input {
  width:100%;
  padding:4px;
  font-size: 11px;
  background-color: white;
  border: 1px solid #d3d3d3;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background color transition */
}

.dropdown-input:hover {
  border-color: #0078D4; /* Darker blue on hover */
}

.dropdown-content {
  display: none; /* Hide dropdown content by default */
  position: absolute; /* Position dropdown content relative to the button */
  background-color: white; /* Dropdown background color */
  width:100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Shadow for dropdown */
  z-index: 1; /* Ensure dropdown is above other content */
}

.dropdown:hover .dropdown-content {
  display: block; /* Show dropdown content on hover */
}

.dropdown-content a {
  color: rgb(117, 117, 117); /* Link color */
  padding: 4px; /* Padding for dropdown items */
  text-decoration: none; /* Remove underline from links */
  display: block; /* Make links fill the dropdown */
  transition: background-color 0.3s; /* Smooth background color transition */
  font-size: 13px;
}

.dropdown-content a:hover {
  background-color: #f1f1f1; /* Background color on link hover */
}

/* Change border color on focus */
.authform-config:not(.locked) input:focus {
  border-color: #0078D4; /* Change to blue when focused */
  outline: none; /* Remove default outline */
}

/* Change border color on hover */
.authform-config:not(.locked) input:hover {
  border-color: #0078D4; /* Change to a darker blue on hover */
}

.locked .authform-inputlabel{
  opacity:50%;
}